import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from '../services/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public outh: AuthService) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const result: any = await this.outh.checkJWTtoken().toPromise();
      this.outh.user_data = result;

      if (!result) {
        throw Error("You need to login");
      }
      let my_route = route.routeConfig.path
      if (my_route.split('/').length == 3)
        my_route = my_route.split('/')[0] + '/' + my_route.split('/')[1]
      if (result.routes.some(r =>
        r.path === '/' + my_route) || my_route == '' || my_route == '401')
        true
      else
        this.router.navigate(['/401'])

      return true
    } catch (error) {
      // console.log(error.message);
      
      if (error.message.message == 'Invalid token!.') {
        this.toastr.warning('Session has been expired, pleas login again!.');
        this.router.navigate(['/users/login'], { queryParams: { returnUrl: state.url } });
        return false
      }
      this.toastr.warning(error.message)
      this.router.navigate(['/users/login'], { queryParams: { returnUrl: state.url } });
      return false
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state)
  }
}
