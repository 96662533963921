export enum API_ROUTES {
    // APP
    LIST_ALL_MODULES = 'app/modules',
    // User
    LIST_USERS_RECORD = 'dash/users',
    LIST_USER_CLASSES = 'dash/users/classes',
    LIST_ACTIVE_USERS_RECORD = 'dash/users/active',
    VIEW_USER_RECORD = 'dash/users/view',
    ADD_USER_RECORD = 'dash/users',
    UPDATE_USER_RECORD = 'dash/users',
    CHANGE_USER_PASSWORD = 'dash/users/change',
    RESET_USER_PASSWORD = 'dash/users/reset',
    ADD_USER__CLASS = 'dash/users/class',

    // Academic
    ACADEMIC_LIST_RECORDS = 'dash/academic',
    ACADEMIC_ACTIVE_RECORD = 'dash/academic/active',
    ACADEMIC_VIEW_RECORD = 'dash/academic/view',
    ACADEMIC_ADD_RECORD = 'dash/academic',
    ACADEMIC_UPDATE_RECORD = 'dash/academic',
    ACADEMIC_ACTIVE_UPDATE_RECORD = 'dash/academic/active',

    // Classes

    // Subjects

    // Sections

    // Exams

    // BillTypes

    // School
    SCHOOL_GET_RECORD = 'app/school',
    SCHOOL_UPDATE_RECORD = 'app/school',
    SCHOOL_UPLOAD_LOGO = 'app/school/logo',
    // Student

    // Parents


    // Dashboard

    //DASH-IDENTITY
    DASH_TOTAL_STUDENTS = 'dash/dashboard/total',
    DASH_TOTAL_SECONDARY = 'dash/dashboard/secondary',
    DASH_TOTAL_INTERMEDIATE = 'dash/dashboard/intermediate',
    DASH_TOTAL_FINANCE = 'dash/dashboard/invoice-reciept',
    // Financial

    // STUDENTBill


    // STUDENT MARKS

    // JOURNAL ENTERY

    // COUNTRIES

    // TRANSFERE

    // LATESTUDENTS

    // Send Public Massages



    // Categories
    ADD_CATEGORY = 'categories/add',
    EDIT_CATEGORY = 'categories',

    // items
    ADD_ITEM = 'items/add',


    // Cusomer
    ADD_CUSTOMER = 'customers/add',



    // Bill
    BILL_LIST = 'bill',
    ADD_BILL = 'bill/add',
    UPDATE_BILL = 'bill/update',
    DELETE_BILL = 'bill',


    // Table  
    UPDATE_TABLE = 'tables',
    UPDATE_WAITER = 'waiter',
    UPDATE_ITEM = 'items/id',



    // Orders


    // Customers

    CUSTOMER_REPORT = 'customers/report'


}